import React, {useEffect} from "react"
import Analytics from "../utils/tracking"
import SEO from "../components/SEO"
import GlobalStyle from "../components/GlobalStyle"
import Nav from "../components/Nav"
import styled from "styled-components"
import flowers1800Logo from "../images/clients/1800flowers/logo.png"
import {windowSize} from "../utils/responsive"
import stockperksLogo from "../images/stockperks-logo.png"
import QR from "../images/clients/1800flowers/1800F-landing-page-qr-code.svg"
import AppStoreLinks from "../components/AppStoreLinks"
import {graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"
import f1800Logo from "../images/clients/1800flowers/1800F-logo.png"
import harryAndDavidLogo from "../images/clients/1800flowers/harry-and-david-logo.png"
import popCornFactoryLogo from "../images/clients/1800flowers/the-pop-corn-factory-logo.png"
import vitalChoiceLogo from "../images/clients/1800flowers/vital-choice-logo.png"
import cherylsCookiesLogo from "../images/clients/1800flowers/cherils-cookies-logo.png"
import sharisBerriesLogo from "../images/clients/1800flowers/sharis-berries-logo.png"
import basketsLogo from "../images/clients/1800flowers/1800baskets-logo.png"
import fruitBouquetsLogo from "../images/clients/1800flowers/fruit-bouquets-logo.png"
import personalizationLogo from "../images/clients/1800flowers/personalization-mall-logo.png"
import simplyChocolateLogo from "../images/clients/1800flowers/simply-chocolate-logo.png"
import thingsRememberedLogo from "../images/clients/1800flowers/things-remembered-logo.png"
import wolfermansBakeryLogo from "../images/clients/1800flowers/wolfermans-bakery-logo.png"

const Flowers1800Page = () => {

    useEffect(() => {
        ;(async () => {
            await Analytics.track("1800 Flowers x Stockperks Landing Page")
        })()
    }, [])

    const data = useStaticQuery(graphql`
        query {
          banner: file(relativePath: { eq: "clients/1800flowers/1800F-banner.png" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
    `);

    return (
        <>
            <SEO
                title="Stockperks x 1800 Flowers"
                url={"/1800flowers"}
            />
            <GlobalStyle />
            <Nav siteTitle={"Stockperks x 1800 Flowers"} />
            <PageContent>
                <Header>
                    <TopLogo src={flowers1800Logo}/>
                    <div style={{height: '100%', borderLeft: "1px solid black"}}></div>
                    <a href={"https://www.stockperks.com"}>
                        <TopLogo style={{marginTop:11}} src={stockperksLogo} />
                    </a>
                </Header>
                <Body>
                    <Content>
                        <Title>1-800-FLOWERS.COM, Inc. is now on Stockperks!</Title>
                        <Row style={{marginBottom: 40}}>
                            <div style={{minWidth: 300, textAlign: "center"}}>
                                <BrandLogo src={f1800Logo} />
                                <BrandLogo src={harryAndDavidLogo} />
                                <BrandLogo src={popCornFactoryLogo} />
                                <BrandLogo src={vitalChoiceLogo} />
                                <BrandLogo src={cherylsCookiesLogo} />
                                <BrandLogo src={sharisBerriesLogo} />
                                <BrandLogo src={basketsLogo} />
                                <BrandLogo src={fruitBouquetsLogo} />
                                <BrandLogo src={personalizationLogo} />
                                <BrandLogo src={simplyChocolateLogo} />
                                <BrandLogo src={thingsRememberedLogo} />
                                <BrandLogo src={wolfermansBakeryLogo} />
                            </div>
                        </Row>

                        <Row>
                            <div>
                                <p>1-800-FLOWERS.COM, Inc. is the leading provider of gifts designed to help inspire customers to give more, connect more, and build
                                    more and better relationships.</p>

                                <p>1-800-FLOWERS.COM, Inc. shareholders now enjoy 25% off* a special collection of flowers and gifts and 20% off*
                                    across our family of brands, all for owning 1 FLWS share!</p>

                                <p>Thank you shareholders!</p>
                            </div>
                        </Row>

                        <Row>
                            <div style={{flex:1}}>
                                <h2>Redeem your Perk!</h2>
                                <p>Download the Stockperks app today and create your profile. Then visit the 1-800-FLOWERS.COM page to claim your benefits!</p>
                                <div style={{display: 'flex', marginTop: 50, marginBottom:50, justifyContent: 'center',}}>
                                    <AppStoreLinks customQrCode={QR}  />
                                </div>
                            </div>
                        </Row>
                        <Row style={{justifyContent:"center"}}>
                            <Banner fluid={data.banner.childImageSharp.fluid} />
                        </Row>
                        <Row>
                            <div>
                                <p style={{fontStyle: "italic", fontSize: 14}}>*Prices & Discounts are exclusive of applicable service and shipping charges and taxes. Items may vary and are subject to availability,
                                    delivery rules and times. Offers cannot be combined or used with any other promotions or discounts, are not available on all products
                                    and are subject to restrictions, limitations, and blackout periods. Offer valid through 1/31/25. Prices and charges are subject to change
                                    without notice.</p>

                                <p style={{fontStyle: "italic", fontSize: 14}}>&copy; 1-800-FLOWERS.COM, INC. 2024.</p>
                            </div>

                        </Row>
                    </Content>
                </Body>
            </PageContent>
        </>
    )

}

export default Flowers1800Page

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding-top: 95px;
  justify-content: center;
  justify-items: center;
  
`

const Header = styled.div`
  display: flex;
  height: 100px;
  padding: 20px;
  flex-direction: row;
  align-items: center;
`

const TopLogo = styled.img`
  max-height: 120px;
  
  margin: 5px;
  @media (max-width: ${windowSize.medium}px) {
    max-height: 80px;
    max-width: 200px;
  }
`

const Body = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const Title = styled.h1`
  font-family: Arial, Helvetica, serif;
  font-size: 35px;
  font-weight: 700;
  color: black;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  @media (max-width: ${windowSize.medium}px) {
    display: block;
  }
`;

const Banner = styled(Img)`
  width: 100%;
  height: auto;
  margin-bottom: 30px;
`

const BrandLogo = styled.img`
  max-width: 150px;
  margin: 0px 30px;
  height: auto;
`;
